import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import aboutbanner from "../assets/images/aboutbanner.jpg";
import banner from "../assets/images/banner/banner.png";
import Sidebar from "./Sidebar";
import PlandetailsCards from "./PlandetailsCards";

function Plandetails() {


  return (
    <div>
      <Header />
      {/* <Sidebar /> */}

        <PlandetailsCards/>

      <Footer />
    </div>
  );
}

export default Plandetails;
