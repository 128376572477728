import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Sitemap() {
  return (
    <div>
      <Header />
      {/* <Sidebar /> */}
      <div className='card mt-5 ml-5 mr-5 mb-5'>
        <div className='cardbody'>
          <section className="service-details">
            <div className="auto-container">
              <div className="row clearfix">
                <div className="col-lg-8 col-md-12 col-sm-12 content-side">
                <h2 style={{color:'rgb(13 55 149)'}} className='text-left mb-5'>Kanavneer private limited pages :</h2>
                  <div className="service-details-content m-4">
                    <div className="content-two">
                      <div className="inner-box ml-4">
                        <div className="single-item">
                        
                          <div className="icon-box">
                            <i className="flaticon-draw-check-mark" />
                          </div>
                          <a href='/'> <h4>Home</h4></a>

                        </div>
                        <div className="single-item">
                          <div className="icon-box"><i className="flaticon-draw-check-mark" /></div>
                          <a href='/about'> <h4>About us</h4></a>
                        </div>
                        <div className="single-item">
                          <div className="icon-box"><i className="flaticon-draw-check-mark" /></div>
                          <a href='/Plan'>  <h4>Plans</h4></a>
                        </div>
                        <div className="single-item">
                          <div className="icon-box"><i className="flaticon-draw-check-mark" /></div>
                        <a href='/howitworks'> <h4>How it works</h4></a> 
                        </div>
                        <div className="single-item">
                          <div className="icon-box"><i className="flaticon-draw-check-mark" /></div>
                         <a href='/contactus'> <h4>Contact us</h4></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Sitemap