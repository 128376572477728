import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import OwlCarousel from "react-owl-carousel";
import banner from "../assets/images/banner/banner.png"; // this is the main image
import testimonials1 from "./testimonial-1.jpg";
import testimonials2 from "./testimonial-2.jpg";
import Sidebar from "./Sidebar";
import homebanner from "../assets/images/homebanner.png";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./caursel.css";
import HomeCards from "./HomeCards";
import Bookingflow from "./Bookingflow";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
    loadimg();
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  const loadimg = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const textColor = {
    color: "#002c8f",
  };
  return (
    <>
      <Header />
      {/* <Sidebar /> */}
      <div class="boxed_wrapper">
        <section className="banner-section">
          <div
            className="shape"
            style={{
              backgroundImage: "url(assets/images/shape/banner-shap.png)",
            }}
          />
          <OwlCarousel
            className="banner-carousel owl-theme owl-carousel owl-dots-none"
            items={1}
            loop
            autoplay
          >
            <div className="slide-item">
              <div className="pattern-box">
                <div className="pattern-1" />
                <div className="pattern-2" />
                <div
                  className="pattern-3"
                  style={{
                    backgroundImage: "url(assets/images/shape/shape-2.png)",
                  }}
                />
              </div>
              <div className="auto-container">
                <div className="inner-box">
                  <div className="image-box">
                    {/* modified */}
                    <figure className="image image-1">
                      <img
                        src={banner}
                        alt="waterfilter"
                        style={{
                          height: "500px",
                          position: "relative",
                          right: "100px",
                          bottom: "75px",
                        }}
                      />
                    </figure>
                  </div>
                  <div className="content-box">
                    <h2>Always Want Safe and Good Water for Healthy Life</h2>
                    <p>
                      Our Family safe and healthy, it is equally essential to
                      filter water before drinking it.
                    </p>
                    <div className="btn-box clearfix">
                      <a
                        href="/Services"
                        className="theme-btn btn-one border-white"
                      >
                        Our Services
                      </a>
                      <a
                        href="/contactus"
                        className="theme-btn banner-btn border-white"
                      >
                        Discover
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-item">
              <div className="pattern-box">
                <div className="pattern-1" />
                <div className="pattern-2" />
                <div
                  className="pattern-3"
                  style={{
                    backgroundImage: "url(assets/images/shape/shape-2.png)",
                  }}
                />
              </div>
              <div className="auto-container">
                <div className="inner-box">
                  <div className="image-box">
                    <figure className="image image-1">
                      <img
                        src={homebanner}
                        alt=""
                        width="700px"
                        height="500px"
                      />
                    </figure>
                  </div>
                  <div className="content-box">
                    <h2>Always Want Safe and Good Water for Healthy Life</h2>
                    <p>
                      Our Family safe and healthy, it is equally essential to
                      filter water before drinking it.
                    </p>
                    <div className="btn-box clearfix">
                      <a
                        href="/Services"
                        className="theme-btn btn-one border-white"
                      >
                        Our Services
                      </a>
                      <a
                        href="/contactus"
                        className="theme-btn banner-btn border-white"
                      >
                        Discover
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </section>

        {/* 3 CARDS  */}
        <HomeCards />

        {/* Kanavneer Services */}

        <section className="service-section bg-color-1" id="service">
          <div
            className="shape"
            style={{ backgroundImage: "url(assets/images/shape/shape-4.png)" }}
          />
          <figure className="image-layer">
            <img
              src={banner}
              alt="waterFilterImage"
              style={{ height: "520px", marginRight: "50px" }}
            />
          </figure>
          <div className="bg-shape">
            <div className="bg-shape-1" />
            <div className="bg-shape-2" />
            <div className="bg-shape-3" />
          </div>
          <div className="auto-container">
            <div className="sec-title centred">
              <h1 style={textColor}>Kanavneer Services</h1>
            </div>
            <div className="row clearfix">
              <div className="col-lg-6 col-md-12 col-sm-12 big-column">
                <div className="left-column text-right">
                  <div
                    className="service-block-one wow fadeInLeft animated animated"
                    data-wow-delay="00ms"
                    data-wow-duration="1500ms"
                    style={{
                      visibility: "visible",
                      animationDuration: "1500ms",
                      animationDelay: "0ms",
                      animationName: "fadeInLeft",
                    }}
                  >
                    <div className="inner-box">
                      <div className="icon-box">
                        <i className="flaticon-water-bottle-1" />
                      </div>
                      <h4>
                        <a href="service-details-2.html">Residential Waters</a>
                      </h4>
                      <p>Lorem ipsum dolor sit amet adilit sed eiusmte mpor.</p>
                    </div>
                  </div>
                  <div
                    className="service-block-one wow fadeInLeft animated animated"
                    data-wow-delay="300ms"
                    data-wow-duration="1500ms"
                    style={{
                      visibility: "visible",
                      animationDuration: "1500ms",
                      animationDelay: "300ms",
                      animationName: "fadeInLeft",
                    }}
                  >
                    <div className="inner-box">
                      <div className="icon-box">
                        <i className="flaticon-water" />
                      </div>
                      <h4>
                        <a href="service-details-4.html">Filtration Plants</a>
                      </h4>
                      <p>Lorem ipsum dolor sit amet adilit sed eiusmte mpor.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 big-column">
                <div className="right-column text-left">
                  <div
                    className="service-block-one wow fadeInRight animated animated"
                    data-wow-delay="00ms"
                    data-wow-duration="1500ms"
                    style={{
                      visibility: "visible",
                      animationDuration: "1500ms",
                      animationDelay: "0ms",
                      animationName: "fadeInRight",
                    }}
                  >
                    <div className="inner-box">
                      <div className="icon-box">
                        <i className="flaticon-water-bottle" />
                      </div>
                      <h4>
                        <a href="service-details-3.html">Commercial Waters</a>
                      </h4>
                      <p>Lorem ipsum dolor sit amet adilit sed eiusmte mpor.</p>
                    </div>
                  </div>
                  <div
                    className="service-block-one wow fadeInRight animated animated"
                    data-wow-delay="300ms"
                    data-wow-duration="1500ms"
                    style={{
                      visibility: "visible",
                      animationDuration: "1500ms",
                      animationDelay: "300ms",
                      animationName: "fadeInRight",
                    }}
                  >
                    <div className="inner-box">
                      <div className="icon-box">
                        <i className="flaticon-water-drop-1" />
                      </div>
                      <h4>
                        <a href="service-details-5.html">Water Softening</a>
                      </h4>
                      <p>Lorem ipsum dolor sit amet adilit sed eiusmte mpor.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* MODIFIED */}

        <Bookingflow />

        {/*  Testimonials */}

        <section className="testimonial-section bg-color-1 py-5 margin-top-50">
          <div className="container">
            <div className="sec-title mb-4">
              <h2 className="text-center" style={textColor}>
                Testimonials
              </h2>
            </div>

            <OwlCarousel
              className="two-column-carousel owl-theme owl-carousel owl-dots-none"
              items={2}
              loop
              autoplay
              responsive={{
                0: {
                  items: 1,
                },
                768: {
                  items: 2,
                },
              }}
            >
              <div
                className="testimonial-block-one mb-4 px-2"
                style={{
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  marginTop: "20px",
                }}
              >
                <div className="inner-box">
                  <figure className="author-thumb">
                    <img
                      src={testimonials1}
                      alt="Testimonial 1"
                      className="img-fluid"
                      style={{ width: "70%", maxWidth: "100%", height: "auto" }}
                    />
                  </figure>
                  <div className="inner">
                    <ul className="rating list-unstyled d-flex justify-content-start mb-2">
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                    </ul>
                    <p>
                      <b>
                        I was looking to get a water purifier without hefty
                        investments.
                      </b>
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="testimonial-block-one mb-4 px-2"
                style={{
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  marginTop: "20px",
                }}
              >
                <div className="inner-box">
                  <figure className="author-thumb">
                    <img
                      src={testimonials1}
                      alt="Testimonial 2"
                      className="img-fluid"
                      style={{ width: "70%", maxWidth: "100%", height: "auto" }}
                    />
                  </figure>
                  <div className="inner">
                    <ul className="rating list-unstyled d-flex justify-content-start mb-2">
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                    </ul>
                    <p>
                      <b>
                        We use Kanavneer water for drinking, cooking, and
                        washing vegetables.
                      </b>
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="testimonial-block-one mb-4 px-2"
                style={{
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  marginTop: "20px",
                }}
              >
                <div className="inner-box">
                  <figure className="author-thumb">
                    <img
                      src={testimonials2}
                      alt="Testimonial 3"
                      className="img-fluid"
                      style={{ width: "70%", maxWidth: "100%", height: "auto" }}
                    />
                  </figure>
                  <div className="inner">
                    <ul className="rating list-unstyled d-flex justify-content-start mb-2">
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                    </ul>
                    <p>
                      <b>
                        We use Kanavneer water for drinking, cooking, and
                        washing vegetables.
                      </b>
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="testimonial-block-one mb-4 px-2"
                style={{
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  marginTop: "20px",
                }}
              >
                <div className="inner-box">
                  <figure className="author-thumb">
                    <img
                      src={testimonials1}
                      alt="Testimonial 4"
                      className="img-fluid"
                      style={{ width: "70%", maxWidth: "100%", height: "auto" }}
                    />
                  </figure>
                  <div className="inner">
                    <ul className="rating list-unstyled d-flex justify-content-start mb-2">
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                    </ul>
                    <p>
                      <b>
                        We use Kanavneer water for drinking, cooking, and
                        washing vegetables.
                      </b>
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="testimonial-block-one mb-4 px-2"
                style={{
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  marginTop: "20px",
                }}
              >
                <div className="inner-box">
                  <figure className="author-thumb">
                    <img
                      src={testimonials2}
                      alt="Testimonial 5"
                      className="img-fluid"
                      style={{ width: "70%", maxWidth: "100%", height: "auto" }}
                    />
                  </figure>
                  <div className="inner">
                    <ul className="rating list-unstyled d-flex justify-content-start mb-2">
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                    </ul>
                    <p>
                      <b>
                        We use Kanavneer water for drinking, cooking, and
                        washing vegetables.
                      </b>
                    </p>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Home;
