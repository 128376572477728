import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import aboutbanner from "../assets/images/aboutbanner.jpg";
import shape3 from "../assets/images/shape/shape3.png";
import { Form } from "react-bootstrap";
import {
  FaSearch,
  FaMapMarkerAlt,
  FaCalendarAlt,
  FaPhoneAlt,
} from "react-icons/fa";
import Contactform from "./Contactform";

function CustomerStories() {
  const [showContactInfo, setShowContactInfo] = useState(false);
  const [name, setName] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name.trim()) {
      setShowContactInfo(true);
    }
  };

  const iconStyle = {
    margin: "0 auto",
    display: "block",
    fontSize: "2rem", // Adjust icon size
    color: "#002c8f", // Adjust icon color
  };

  const [banners, setBanners] = useState([]);
  const handleUpdate = (data) => {
    setBanners(data);
  };

  return (
    <div>
      <Header onBannersData={handleUpdate} />
      <section
        className="page-title centred"
        style={{ backgroundImage: `url(${aboutbanner})` }}
      >
        <div className="shape" />
        <div className="auto-container">
          <div className="content-box">
            <h1 style={{ color: "#002c8f" }}>Contact Us</h1>
            <ul className="bread-crumb clearfix">
              <li>
                <a href="/">Home</a>
              </li>
              <li>Contact Us</li>
            </ul>
          </div>
        </div>
      </section>

      {/* Display Kanvneer Information */}
      <section
        className="feature-section centred m-5"
        style={{ marginTop: "20px" }}
      >
        <div className="auto-container">
          <div data-wow-delay="00ms" data-wow-duration="1500ms">
            <div className="row clearfix">
              <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                <div className="feature-block-one">
                  <div className="inner-box">
                    <div
                      className="shape"
                      style={{
                        backgroundImage: `url(${
                          "https://api.kanavneer.com/" + banners.aboutusImage
                        })`,
                      }}
                    />
                    <div className="icon-box">
                      <FaSearch style={iconStyle} /> {/* Search Icon */}
                    </div>
                    <h4 style={{ textAlign: "center" }}>Search</h4>
                    <p style={{ textAlign: "center" }}>
                      Easily find what you’re looking for using our search bar
                      with filters and suggestions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                <div className="feature-block-one">
                  <div className="inner-box">
                    <div
                      className="shape"
                      style={{ backgroundImage: `url(${shape3})` }}
                    />
                    <div className="icon-box">
                      <FaMapMarkerAlt style={iconStyle} /> {/* Location Icon */}
                    </div>
                    <h4 style={{ textAlign: "center" }}>Location</h4>
                    <p style={{ textAlign: "center" }}>
                      Visit us at [Address]. See our location on the map and get
                      directions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                <div className="feature-block-one">
                  <div className="inner-box">
                    <div
                      className="shape"
                      style={{ backgroundImage: `url(${shape3})` }}
                    />
                    <div className="icon-box">
                      <FaCalendarAlt style={iconStyle} />{" "}
                      {/* Availability Icon */}
                    </div>
                    <h4 style={{ textAlign: "center" }}>Availability</h4>
                    <p style={{ textAlign: "center" }}>
                      Check availability and book your slot directly through our
                      calendar.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                <div className="feature-block-one">
                  <div className="inner-box">
                    <div
                      className="shape"
                      style={{ backgroundImage: `url(${shape3})` }}
                    />
                    <div className="icon-box">
                      <FaPhoneAlt style={iconStyle} /> {/* Contact Icon */}
                    </div>
                    <h4 style={{ textAlign: "center" }}>Contact</h4>
                    <p style={{ textAlign: "center" }}>
                      Reach out to us via our contact form, email, or phone.
                      We’re here to help!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Display Form and Contact Info */}
      {!showContactInfo ? (
        <section className="contact-form-section m-5">
          <div className="auto-container">
            <div className="contact-form">
              <div className="contact-info">
                <h5
                  style={{
                    color: "#002c8f",
                    textAlign: "center",
                  }}
                >
                  Search Your Location
                </h5>
                <p className="text-center">
                  Kanvneer operates on a proven franchise model that combines a
                  strong brand presence with comprehensive support for its
                  franchisees. Here’s what you can expect: Initial Investment:
                  The initial investment includes the franchise fee, equipment,
                  inventory, and setup costs. Exact figures will vary based on
                  location and store size. Training and Support: Kanvneer
                  provides extensive training covering product knowledge, sales
                  techniques, and operational procedures. Ongoing support is
                  available to ensure franchisees have the tools they need for
                  success.
                </p>
                <form onSubmit={handleSubmit}>
                  <Form.Group
                    className="mb-3"
                    controlId="formName"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Search your location"
                      value={name}
                      onChange={handleChange}
                      style={{
                        border: "0.1px solid lightgray",
                        display: "inline-block",
                        width: "60%",
                      }}
                    />
                  </Form.Group>
                  <div
                    className="form-group message-btn"
                    style={{ textAlign: "center" }}
                  >
                    <button
                      type="submit"
                      className="theme-btn btn-one"
                      style={{ marginTop: "20px" }}
                    >
                      Search
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <>
          <Contactform />

          <div className="row">
            <div className="col-lg-12">
              <div id="google-map" className="google-map pb-3">
                <iframe
                  src="https://maps.google.com/maps?q=SHREE+LUXMI+GANESH+UDYOG+PRIVATE+LIMITED+++++++++++++++++++HNO+4-7-8%2F17%2F1%2FB+Flat+No.103%2FA%2F1%2C+Shivani+Residency%2C+Bypass+Road%2C+Sangareddy%2C+Telangana%2C+502001&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  style={{
                    height: "400px",
                    width: "100%",
                    borderRadius: 20,
                    marginLeft: "10px",
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <Footer />
    </div>
  );
}

export default CustomerStories;
