import { Routes, Route } from "react-router-dom";
import Home from "./Component/Home";
import About from "./Component/About";
import Plan from "./Component/Plan";
import HowitWorks from "./Component/Howitworks";
import CustomerStories from "./Component/CustomerStories";
import Advantage from "./Component/Advantage";
import Termsandconditions from '../src/Component/Termsandconditions';
import Privacypolicy from "./Component/Privacypolicy";
import Plandetails from "./Component/Plandetails";
import Refundpolicy from "./Component/Refundpolicy";
import Sitemap from "./Component/Sitemap";
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/plan" element={<Plan />} />
        <Route path="/howitworks" element={<HowitWorks />} />
        <Route path="/contactus" element={<CustomerStories />} />
        <Route path="/advantage" element={<Advantage />} />
        <Route path="/Privacypolicy" element={<Privacypolicy />} />
        <Route path="/Termsandconditions" element={<Termsandconditions />} />
        <Route path="/Plandetails" element={<Plandetails />} />
        <Route path="/Refundpolicy" element={<Refundpolicy />} />
        <Route path="/Sitemap" element={<Sitemap />} />
      </Routes>
    </div>
  );
}

export default App;


































