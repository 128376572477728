import React from "react";
import kalvaneerlogo from "../assets/images/kalvaneerlogo.png";
import { NavLink } from "react-router-dom";
import appstore from '../assets/images/appstore.gif';
import googlestore from '../assets/images/googlestore.gif';
function Footer() {
  return (
    <div>
      <footer className="main-footer">
        <div className="pattern-layer">
        </div>
        <div className="auto-container">
          <div className="widget-section">
            <div className="row clearfix">
              <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                <div className="footer-widget logo-widget">
                  <figure className="footer-logo">
                    <a href="/">
                      <img src={kalvaneerlogo} alt="" />
                    </a>
                  </figure>
                  <div className="text">
                    <p>
                      Kanavneer Water Purifier Company specializes in providing
                      water purifiers on rent and comprehensive servicing. Our
                      mission is to make clean and safe drinking water
                      accessible to all...<br /> <a href="/about" >Read More</a>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                <div className="footer-widget links-widget" style={{marginLeft:"120px"}}>
                  <div className="widget-title">
                    <h4 className="mb-4">Quick Links</h4>
                  </div>
                  <div className="widget-content">
                    <ul className="links-list clearfix">
                      <li>
                        <NavLink to="/">Home</NavLink>
                      </li>
                      <li>
                        <NavLink to="/About">About</NavLink>
                      </li>
                      <li>
                        <NavLink to="/Subscription">Subscription</NavLink>
                      </li>
                      <li>
                        <NavLink to="/Plan">Plan</NavLink>
                      </li>
                      <li>
                        <NavLink to="/HowitWorks">How it Works</NavLink>
                      </li>
                      <li>
                        <NavLink to="/contactus">
                        Contact Us
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 footer-column">
                <div className="footer-widget contact-widget ml-70">
                  <div className="widget-title">
                    <h4 className="mb-4">Address</h4>
                  </div>
                  <div className="widget-content">
                    <ul className="info-list clearfix">
                      <li>
                        <i className="fal fa-map-marker-alt text-white" />
                        SHREE LUXMI GANESH UDYOG PRIVATE LIMITED
                        HNO 4-7-8/17/1/B Flat No.103/A/1, Shivani Residency, Bypass Road, Sangareddy, Telangana, 502001
                      </li>
                      <li>
                      <i class="fa fa-address-book text-white" aria-hidden="true"></i>
                       <a href="tel:+91 8500275777">+91 85 00 275 777 </a>,  
                         <a href="tel:+91 7799018777">+91 77 99 018 777</a>
                      </li>
                      <li>
                        <i className="fal fa-envelope-open-text text-white" />
                        <a href="mailto:info@kanavneer.com">
                          info@kanavneer.com
                        </a> 
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 footer-column">
                <div className="footer-widget subscribe-widget">

                  <h4 className="mb-4 ml-4" style={{ color: 'white' }}>Available In</h4>

                  <a href="https://play.google.com/store/apps?hl=en_IN&gl=US"target="blank">
                    <img src={googlestore} width='100px'height='70px' className="mb-2  ml-4" /> </a> <br />
                  <a href="https://play.google.com/store/apps?hl=en_IN&gl=US"target="blank">
                    <img src={appstore} width='100px' className="ml-4 " />  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
  <div className="auto-container">
    <div className="bottom-inner">
      <div className="copyright">
        <p>
          © 2024 <a href="/" className="text-white">Kanavneer</a> All Right Reserved-Designed by{" "}
          <a href="https://www.digitalraiz.co.in/" target="blank">
            <span className="text-white">Digitalraiz</span>
          </a>
        </p>
      </div>
      <ul className="social-links clearfix">
        <li>
          <a href="https://www.facebook.com/" target="blank">
            <i className="fab fa-facebook-f" />
          </a>
        </li>
        <li>
          <a href="https://twitter.com/" target="blank">
            <i className="fab fa-twitter" />
          </a>
        </li>
        <li>
          <a href="https://www.linkedin-in.com/" target="blank">
            <i className="fab fa-linkedin-in" />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/" target="blank">
            <i className="fab fa-instagram" />
          </a>
        </li>
      </ul>
      <ul className="footer-nav clearfix">
        <li>
          <a href="/Sitemap">Site Map</a>
        </li>
        <li>
          <a href="/Privacypolicy">Privacy policy</a>
        </li>
        <li>
          <a href="/Refundpolicy">Refund Policy</a>
        </li>
        <li>
          <a href="/Termsandconditions">Terms and conditions</a>
        </li>
      </ul>
    </div>
  </div>
</div>

      </footer>
        <a id="totop" href="#top" className="top-visible" style={{display: 'block'}}>
        <i className="fa fa-angle-up" />
      </a>
    </div>
  );
}

export default Footer;
