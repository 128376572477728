import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import purifier1 from "../assets/images/Home/purifier1.jpg";
import Sidebar from "./Sidebar";
function Advantage() {
  return (
    <div>
      <Header />
      {/* <Sidebar /> */}
      <section className="service-details">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="content-one">
              <div className="text">
                <h2>
                  The uniqueness and advantages of your water purification
                  technology.
                </h2>
                <p>
                  Our water purification technology at Kanavneer stands out due
                  to its cutting- edge innovation and the distinct advantages it
                  offers over traditional purification methods. Here&#39;s why
                  our technology is unique and how it benefits both our
                  customers and the environment:
                </p>
              </div>
              <figure className="image-box">
                <img src={purifier1} alt="" width="35%" height="50%" />
              </figure>
            </div>

            <div className="row ">
              <div className="single-item">
                <h4>1. Advanced Filtration Process:</h4>
                <p>
                  {" "}
                  Our water purification technology employs an advanced
                  multi-stage filtration process that effectively removes
                  impurities, contaminants, bacteria, and harmful substances
                  from the water. This ensures that the water delivered is not
                  just clean but also safe for consumption..
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Advantage;
