import React, { useState, useEffect } from "react";
import shape7 from "../assets/images/shape/shape7.png";
import banner from '../assets/images/banner/banner.png';

function HomeCards() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [showFirstSet, setShowFirstSet] = useState(true);

  const handleFirstButtonClick = () => {
    setShowFirstSet(true);
  };

  const handleSecondButtonClick = () => {
    setShowFirstSet(false);
  };

  const firstSet = (
    <div className="row clearfix">
      <div className="col-lg-4 col-md-6 col-sm-12 shop-block mb-4">
        <div className="shop-block-one wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500m">
          <div className="inner-box">
            <figure className="image-box">
              <img src={banner} alt="Duo" height="100%" style={{margin:"auto"}} />
            </figure>
            <div className="lower-content">
              <div className="shape" style={{ backgroundImage: `url(${shape7})` }} />
              <h4 style={{color:"#002c8f"}}>
              Duo
              </h4>
              <h6>₹ 349/month</h6>
              <p>It is a well-known fact that water is a basic necessity for everyone.</p>
              <div className="btn-box">
                <a href="/Plandetails" className="theme-btn btn-two">View Details</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-12 shop-block mb-4">
        <div className="shop-block-one wow fadeInUp animated" data-wow-delay="300ms" data-wow-duration="1500m">
          <div className="inner-box">
            <figure className="image-box">
              <img src={banner} alt="Quad" />
            </figure>
            <div className="lower-content">
              <div className="shape" style={{ backgroundImage: `url(${shape7})` }} />
              <h4 style={{color:"#002c8f"}}>
              Quad
              </h4>
              <h6>₹ 499/month</h6>
              <p>It is a well-known fact that water is a basic necessity for everyone.</p>
              <div className="btn-box">
                <a href="/Plandetails" className="theme-btn btn-two">View Details</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-12 shop-block mb-4">
        <div className="shop-block-one wow fadeInUp animated" data-wow-delay="300ms" data-wow-duration="1500m">
          <div className="inner-box">
            <figure className="image-box">
              <img src={banner} alt="Hexa" />
            </figure>
            <div className="lower-content">
              <div className="shape" style={{ backgroundImage: `url(${shape7})` }} />
              <h4 style={{color:"#002c8f"}}>
              Hexa
              </h4>
              <h6>₹ 649/month</h6>
              <p>It is a well-known fact that water is a basic necessity for everyone.</p>
              <div className="btn-box">
                <a href="/Plandetails" className="theme-btn btn-two">View Details</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const secondSet = (
    <div className="row clearfix">
      <div className="col-lg-4 col-md-6 col-sm-12 shop-block mb-4">
        <div className="shop-block-one wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500m">
          <div className="inner-box">
            <figure className="image-box">
              <img src={banner} alt="Duo" height="100%" />
            </figure>
            <div className="lower-content">
              <div className="shape" style={{ backgroundImage: `url(${shape7})` }} />
              <h4 style={{color:"#002c8f"}}>
              Duo
              </h4>
              <h6>₹ 31322/month</h6>
              <p>It is a well-known fact that water is a basic necessity for everyone.</p>
              <div className="btn-box">
                <a href="/Plandetails" className="theme-btn btn-two">View Details</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-12 shop-block mb-4">
        <div className="shop-block-one wow fadeInUp animated" data-wow-delay="300ms" data-wow-duration="1500m">
          <div className="inner-box">
            <figure className="image-box">
              <img src={banner} alt="Quad" />
            </figure>
            <div className="lower-content">
              <div className="shape" style={{ backgroundImage: `url(${shape7})` }} />
              <h4 style={{color:"#002c8f"}}>
              Quad
              </h4>
              <h6>₹ 6465/month</h6>
              <p>It is a well-known fact that water is a basic necessity for everyone.</p>
              <div className="btn-box">
                <a href="/Plandetails" className="theme-btn btn-two">View Details</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-12 shop-block mb-4">
        <div className="shop-block-one wow fadeInUp animated" data-wow-delay="300ms" data-wow-duration="1500m">
          <div className="inner-box">
            <figure className="image-box">
              <img src={banner} alt="Hexa" />
            </figure>
            <div className="lower-content">
              <div className="shape" style={{ backgroundImage: `url(${shape7})` }} />
              <h4 style={{color:"#002c8f"}}>
              Hexa
              </h4>
              <h6>₹ 32313/month</h6>
              <p>It is a well-known fact that water is a basic necessity for everyone.</p>
              <div className="btn-box">
                <a href="/Plandetails" className="theme-btn btn-two">View Details</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const buttonStyle = {
    active: {
      backgroundColor: '#002c8f',
      color: '#fff',
    },
    inactive: {
      backgroundColor: '#b1b1b16b',
      color: '#000',
    },
  };

  return (
    <div className="container mt-5">
      <section className="shop-page-section">
        <div className="sec-title text-center mb-4">
          <h2 style={{marginBottom:"-30px"}}>
            All Plans
          </h2>
        </div>
        <div className="text-center mb-4">
          <button
            style={showFirstSet ? buttonStyle.active : buttonStyle.inactive}
            className="btn me-2 m-4"
            onClick={handleFirstButtonClick}
          >
            Launching Plans
          </button>
          <button
            style={!showFirstSet ? buttonStyle.active : buttonStyle.inactive}
            className="btn"
            onClick={handleSecondButtonClick}
          >
            Regular Plans
          </button>
        </div>
        {showFirstSet ? firstSet : secondSet}
      </section>
    </div>
  );
}

export default HomeCards;
