import React from 'react'
import facebook from "../assets/images/facebook.png";
import insta1 from "../assets/images/insta1.png";
import linkedin from "../assets/images/linkedin.png";
import twitter from "../assets/images/twitter.png";
import whatsappremovebg from '../assets/images/whatsappremovebg.png';


function Sidebar() {
    const divStyle = {
        backdropFilter: 'blur(12px)',
        background: 'rgba(255, 255, 255, 0.6)',
        boxShadow: '0 3px 10px rgba(0, 0, 0, 0.176)',
      };
    return (
        <div>
            <div class="menu-container" style={divStyle} >
                <div class="collapse-icon">
                    <span class="social_media_text"></span>

                </div>
                <div class="menu-item" >
                    <a href="https://www.facebook.com/digitalraiz/" target="_blank" style={{ padding: "0px" ,width:'37px'}}>
                        <img src={facebook} alt=""  />

                    </a>
                </div>
                <div class="menu-item">
                    <a href="https://twitter.com/digital_raiz/" target="_blank" style={{ padding: "0px",width:'35px' }}>
                        <img src={twitter} alt="" /></a>

                </div>

                <div class="menu-item">
                    <a href="https://www.linkedin.com/company/71333221/admin/" target="_blank" style={{ padding: "0px" ,width:'37px'}}>
                        <img src={linkedin} alt="" width='35px' />

                    </a>
                </div>
                <div class="menu-item">
                    <a href="https://www.instagram.com/digital_raiz/" target="_blank" style={{ padding: "0px" ,width:'37px'}}>
                        <img src={insta1} alt="" width='35px' />

                    </a>
                </div>
                <div class="menu-item" id='socialmedia'>
                    <a href="https://web.whatsapp.com/send?phone=917799018777&amp;text=" target="_blank" tooltip="WhatsApp">
                        <img src={whatsappremovebg}  /></a>
                </div>
            </div>
        </div>
    )
}

export default Sidebar