import React, { useState, useEffect } from "react";
import pagetitle from "../assets/images/background/video1.jpg";
import bannershape from "../assets/images/shape/bannershap.png";
import shape7 from "../assets/images/shape/shape7.png";
import Footer from "./Footer";
import Header from "./Header";
import Accordion from "react-bootstrap/Accordion";
import img4 from "../assets/images/Home/sized/img4.png";
import img7 from "../assets/images/Home/sized/img7.png";
import img6 from "../assets/images/Home/sized/img6.png";
import banner from '../assets/images/banner/banner.png';
import contactbanner from '../assets/images/contactbanner.jpg';
import Sidebar from "./Sidebar";

function Plan() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [showFirstSet, setShowFirstSet] = useState(true);

  const handleFirstButtonClick = () => {
    setShowFirstSet(true);
  };

  const handleSecondButtonClick = () => {
    setShowFirstSet(false);
  };

  const firstSet = (
    <div className="row clearfix">
      <div className="col-lg-4 col-md-6 col-sm-12 shop-block">
        <div className="shop-block-one wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500m">
          <div className="inner-box">
            <figure className="image-box">
              <img src={banner} alt="" height="100%" />
            </figure>
            <div className="lower-content">
              <div className="shape" style={{ backgroundImage: `url(${shape7})` }} />
              <h4>
                <a href="/plan">Duo</a>
              </h4>
              <h6>₹ 349/month</h6>
              <p>It is a well-known fact that water is a basic necessity for everyone.</p>
              <div className="btn-box">
                <a href="/Plandetails" className="theme-btn btn-two">View Details</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-12 shop-block">
        <div className="shop-block-one wow fadeInUp animated" data-wow-delay="300ms" data-wow-duration="1500m">
          <div className="inner-box">
            <figure className="image-box">
              <img src={banner} alt="" />
            </figure>
            <div className="lower-content">
              <div className="shape" style={{ backgroundImage: `url(${shape7})` }} />
              <h4>
                <a href="/plan">Quad</a>
              </h4>
              <h6>₹ 499/month</h6>
              <p>It is a well-known fact that water is a basic necessity for everyone.</p>
              <div className="btn-box">
                <a href="/Plandetails" className="theme-btn btn-two">View Details</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-12 shop-block">
        <div className="shop-block-one wow fadeInUp animated" data-wow-delay="300ms" data-wow-duration="1500m">
          <div className="inner-box">
            <figure className="image-box">
              <img src={banner} alt="" />
            </figure>
            <div className="lower-content">
              <div className="shape" style={{ backgroundImage: `url(${shape7})` }} />
              <h4>
                <a href="/plan">Hexa</a>
              </h4>
              <h6>₹ 649/month</h6>
              <p>It is a well-known fact that water is a basic necessity for everyone.</p>
              <div className="btn-box">
                <a href="/Plandetails" className="theme-btn btn-two">View Details</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const secondSet = (
    <div className="row clearfix">
      <div className="col-lg-4 col-md-6 col-sm-12 shop-block">
        <div className="shop-block-one wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500m">
          <div className="inner-box">
            <figure className="image-box">
              <img src={banner} alt="" height="100%" />
            </figure>
            <div className="lower-content">
              <div className="shape" style={{ backgroundImage: `url(${shape7})` }} />
              <h4>
                <a href="/plan">Duo</a>
              </h4>
              <h6>₹ 31322/month</h6>
              <p>It is a well-known fact that water is a basic necessity for everyone.</p>
              <div className="btn-box">
                <a href="/Plandetails" className="theme-btn btn-two">View Details</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-12 shop-block">
        <div className="shop-block-one wow fadeInUp animated" data-wow-delay="300ms" data-wow-duration="1500m">
          <div className="inner-box">
            <figure className="image-box">
              <img src={banner} alt="" />
            </figure>
            <div className="lower-content">
              <div className="shape" style={{ backgroundImage: `url(${shape7})` }} />
              <h4>
                <a href="/plan">Quad</a>
              </h4>
              <h6>₹ 6465/month</h6>
              <p>It is a well-known fact that water is a basic necessity for everyone.</p>
              <div className="btn-box">
                <a href="/Plandetails" className="theme-btn btn-two">View Details</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-12 shop-block">
        <div className="shop-block-one wow fadeInUp animated" data-wow-delay="300ms" data-wow-duration="1500m">
          <div className="inner-box">
            <figure className="image-box">
              <img src={banner} alt="" />
            </figure>
            <div className="lower-content">
              <div className="shape" style={{ backgroundImage: `url(${shape7})` }} />
              <h4>
                <a href="/plan">Hexa</a>
              </h4>
              <h6>₹ 32313/month</h6>
              <p>It is a well-known fact that water is a basic necessity for everyone.</p>
              <div className="btn-box">
                <a href="/Plandetails" className="theme-btn btn-two">View Details</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const buttonStyle = {
    active: {
      backgroundColor: '#002c8f',
      color: '#fff',
    },
    inactive: {
      backgroundColor: '#b1b1b16b',
      color: '#000',
    },
  };
  
  const [banners, setBanners] = useState([]);
  const handleUpdate = (data) => {
    setBanners(data);
  };

  return (
    <div>
      <Header onBannersData={handleUpdate} />
      {/* <Sidebar /> */}
      <section
        className="page-title centred"
        style={{ backgroundImage: `url(${"https://api.kanavneer.com/"+ banners.allPlansImage})` }}
      >
        <div className="shape" />
        <div className="auto-container">
          <div className="content-box">
            <h1 style={{color:"#002c8f"}}>All Plans</h1>
            <ul className="bread-crumb clearfix">
              <li>
                <a href="/">Home</a>
              </li>
              <li>Plan</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="shop-page-section">
        <div className="auto-container">
          <div className="sec-title">
            <h2 className="text-center">All Plans</h2>
            <p className="text-center" style={{marginBottom:"-30px"}}>
              We have carefully crafted three rental plans to suit the
              diverse needs of our customers, ensuring that<br /> every
              household can access clean and safe drinking water at an
              affordable cost:
            </p>
          <div className="text-center my-4">
            <button
              style={showFirstSet ? buttonStyle.active : buttonStyle.inactive}
              className="btn me-2 m-4"
              onClick={handleFirstButtonClick}
            >
              Launching Plans
            </button>
            <button
              style={!showFirstSet ? buttonStyle.active : buttonStyle.inactive}
              className="btn"
              onClick={handleSecondButtonClick}
            >
              Regular Plans
            </button>
          </div>
          {showFirstSet ? firstSet : secondSet}
        </div>
        </div>
      </section>

      <section className="faq-page-section" style={{marginTop:"-90px"}}>
        <div className="auto-container" >
          <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12 content-column">
              <div className="faq-content">
                <div className="sec-title">
                <div
                    className="sec-title"
                    data-aos="fade-left"
                    data-aos-duration="1000"
                   
                  >
                    <h2 className="text-center" style={{marginBottom:"-40px"}} >
                      FAQ'S
                    </h2>
                  </div>
                </div>

                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <h4 style={{ fontSize: '16px', textTransform: 'capitalize' }}>How often should I replace the filters?</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        We recommend replacing the filters every 6 to 12 months, depending on usage and environmental conditions.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <h4 style={{ fontSize: '16px', textTransform: 'capitalize' }}>Is the purifier noisy?</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        No, our purifier is designed to operate quietly, ensuring a peaceful environment while it cleans the air.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <h4 style={{ fontSize: '16px', textTransform: 'capitalize' }}>
                        Can I use the purifier overnight?
                      </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Yes, our purifier is safe to use overnight. Its low noise level and energy-efficient operation make it ideal for continuous use.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      {" "}
                      <h4 style={{ fontSize: '16px', textTransform: 'capitalize' }}>How do I clean the purifier?</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Simply wipe the exterior with a soft, damp cloth. For internal cleaning, follow the instructions in the user manual.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      {" "}
                      <h4 style={{ fontSize: '16px', textTransform: 'capitalize' }}>What size room does the purifier cover?</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Our purifier is suitable for rooms up to [specify square footage], providing effective air purification throughout.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>

          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Plan;
