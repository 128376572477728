import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function Bookingflow() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const activeButtonStyle = {
    backgroundColor: "#002c8f",
    color: "#fff",
  };

  const inactiveButtonStyle = {
    backgroundColor: "#e0e0e0",
    color: "#000",
  };

  return (
    <section>
      <div className="container">
        <div className="tabs -pills-2 js-tabs">
          <h2
            className="sectionTitle__title text-center mt-4 mb-3"
            style={{ color: "#002c8f" }}
          >
            {t("How it works")}
          </h2>
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8">
              {/* Buttons container */}
              <div className="d-flex flex-wrap justify-content-center js-tabs-controls mb-4">
                {[1, 2, 3, 4, 5].map((index) => (
                  <div className="mx-1 mb-2" key={index}>
                    <button
                      onClick={() => handleTabClick(index)}
                      className="tabs__button rounded-pill text-14 fw-500 px-3 py-2"
                      style={
                        activeTab === index
                          ? activeButtonStyle
                          : inactiveButtonStyle
                      }
                      data-tab-target={`.-tab-item-${index}`}
                    >
                      {t(getTabLabel(index))}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="tabs__content pt-4">
            {[1, 2, 3, 4, 5].map((index) => (
              <div
                key={index}
                className={`tabs__pane ${
                  activeTab === index ? "is-tab-el-active" : ""
                } -tab-item-${index}`}
              >
                <div className="row">
                  <div className="col-12 col-md-6 mb-4">
                    <div className="d-flex justify-content-center">
                      <img
                        className="img-fluid"
                        src={`img/p${index}.png`}
                        style={{ maxHeight: "400px", width: "auto" }}
                        alt={`Tab ${index} Image`}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 text-center">
                    <h2 className="text-30 lh-15">{t("Download")}</h2>
                    <p className="text-dark-1 mt-3">{t("Booking Advice")}</p>
                    <div className="row justify-content-center">
                      <div className="col-md-6 text-center mb-4">
                        <img
                          className="mt-3 img-fluid"
                          style={{ width: "100px", margin: "auto" }}
                          src="https://static.wixstatic.com/media/1f9881_141a143af5d9476aa4e006846ff85621~mv2.png/v1/fill/w_420,h_420,al_c,lg_1,q_85,usm_0.33_1.00_0.00,enc_auto/frame%20(2).png"
                          alt="Scan QR"
                        />
                        <p>
                          <b>{t("Scanhere")}</b>
                        </p>
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex flex-column">
                          <div className="d-flex align-items-center px-2 py-2 rounded-8 border-white-15 text-white bg-dark-3 mb-2">
                            <div className="icon-apple text-24" />
                            <div className="ml-2">
                              <div className="text-14">{t("Download On")}</div>
                              <div className="text-15 lh-1 fw-500">
                                {t("Apple Store")}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center px-2 py-2 rounded-8 border-white-15 text-white bg-dark-3">
                            <div className="icon-android text-24" />
                            <div className="ml-2">
                              <div className="text-14">{t("Get in On")}</div>
                              <div className="text-8 lh-1 fw-500">
                                {t("Google Play Store")}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

const getTabLabel = (index) => {
  switch (index) {
    case 1:
      return "Download App";
    case 2:
      return "Select Plan";
    case 3:
      return "Verification";
    case 4:
      return "Review Booking";
    case 5:
      return "Completed";
    default:
      return "";
  }
};

export default Bookingflow;
