import React, { useState } from 'react';
import banner from "../assets/images/banner/banner.png";

const PlandetailsCards = () => {
  const [selectedPlan, setSelectedPlan] = useState('Duo');

  const borderText = {
    color: "#002c8f",
    border: "#002c8f"
  };

  const handlePlanChange = (plan) => {
    setSelectedPlan(plan);
  };

  return (
    <div className="container my-5">
      <div data-section="plan-section" className="mt-6" id="choose-plan-section">
        <ul data-component="plan-category" className="flex flex-wrap justify-center gap-2 md:gap-6 mt-8 px-2 mx-auto">
          <li className="flex-1 min-w-[150px] max-w-[200px]">
            <div
              style={{ ...borderText, boxShadow: '0 4px 8px rgba(173, 216, 230, 0.6)' }}
              className={`flex flex-col justify-center items-center overflow-hidden border rounded-[20px] border-[rgba(0,0,0,0.1)] ${selectedPlan === 'Duo' ? 'border-2 border-primary scale-105' : ''} hover:cursor-pointer`}
              onClick={() => handlePlanChange('Duo')}
            >
              <div className="flex flex-col items-center justify-center p-2" style={{cursor: 'pointer'}}>
                <h3 className="text-[13px] md:text-sm font-bold capitalize" style={borderText}>
                  Duo
                </h3>
              </div>
              <div className="flex flex-col justify-center w-full h-[60%] bg-[#F8F9FF] md:bg-[#EEF1FF] p-2" style={{cursor: 'pointer'}}>
                <h3 className="text-[13px] md:text-sm font-medium text-center capitalize" style={borderText}>
                  100 liters
                </h3>
                <h4 className="font-medium text-center text-[11px]" style={borderText}>
                  Ltrs/month
                </h4>
              </div>
            </div>
          </li>
          <li className="flex-1 min-w-[150px] max-w-[200px]">
            <div
              style={{ ...borderText, boxShadow: '0 4px 8px rgba(173, 216, 230, 0.6)' }}
              className={`flex flex-col justify-center items-center overflow-hidden border rounded-[20px] border-[rgba(0,0,0,0.1)] ${selectedPlan === 'Quad' ? 'border-2 border-primary scale-105' : ''} hover:cursor-pointer`}
              onClick={() => handlePlanChange('Quad')}
            >
              <div className="flex flex-col items-center justify-center p-2" style={{cursor: 'pointer'}}>
                <h3 className="text-[13px] md:text-sm font-bold capitalize" style={borderText}>
                  Quad
                </h3>
              </div>
              <div className="flex flex-col justify-center w-full h-[60%] bg-[#F8F9FF] md:bg-[#EEF1FF] p-2">
                <h3 className="text-[13px] md:text-sm font-medium text-center capitalize" style={borderText}>
                  200 liters
                </h3>
                <h4 className="font-medium text-center text-[11px]" style={borderText}>
                  Ltrs/month
                </h4>
              </div>
            </div>
          </li>
          <li className="flex-1 min-w-[150px] max-w-[200px]">
            <div
              style={{ ...borderText, boxShadow: '0 4px 8px rgba(173, 216, 230, 0.6)' }}
              className={`flex flex-col justify-center items-center overflow-hidden border rounded-[20px] border-[rgba(0,0,0,0.1)] ${selectedPlan === 'Hexa' ? 'border-2 border-primary scale-105' : ''} hover:cursor-pointer`}
              onClick={() => handlePlanChange('Hexa')}
            >
              <div className="flex flex-col items-center justify-center p-2" style={{cursor: 'pointer'}}>
                <h3 className="text-[13px] md:text-sm font-bold capitalize" style={borderText}>
                  Hexa
                </h3>
              </div>
              <div className="flex flex-col justify-center w-full h-[60%] bg-[#F8F9FF] md:bg-[#EEF1FF] p-2">
                <h3 className="text-[13px] md:text-sm font-medium text-center capitalize" style={borderText}>
                  300 liters
                </h3>
                <h4 className="font-medium text-center text-[11px]" style={borderText}>
                  Ltrs/month
                </h4>
              </div>
            </div>
          </li>
        </ul>
        <div className="text-center my-6 text-xl font-bold" style={borderText}>{selectedPlan} Plan Details</div>
        
        {selectedPlan === 'Duo' && (
          <div className="row">
            <div className="col-12 col-md-6 mb-4">
              <img src={banner} alt="Banner" className="img-fluid w-50 mb-3" />
              <div className="table-responsive">
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr className="text-center">
                      <th>Advantages</th>
                      <th>Info</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      <td>Flexible Installation</td>
                      <td>Flexible Installation</td>
                    </tr>
                    <tr className="text-center">
                      <td>Smart LED Indication</td>
                      <td>Smart LED Indication</td>
                    </tr>
                    <tr className="text-center">
                      <td>Long Cartridge Life</td>
                      <td>Long Cartridge Life</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-12 col-md-6 mb-4">
              <div className="table-responsive">
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr className="text-center text-white bg-primary">
                      <th>Benefits</th>
                      <th>3 months</th>
                      <th>6 months</th>
                      <th>12 months</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      <td style={{ fontWeight: "bold" }}>Recharge</td>
                      <td>1047</td>
                      <td>2094</td>
                      <td>4188</td>
                    </tr>
                    {/* <tr className="text-center">
                      <td style={{ fontWeight: "bold" }}>Assembly Material</td>
                      <td>399</td>
                      <td>399</td>
                      <td>399</td>
                    </tr> */}
                    <tr className="text-center">
                      <td style={{ fontWeight: "bold" }}>Discount</td>
                      <td>99</td>
                      <td>199</td>
                      <td>399</td>
                    </tr>
                    {/* <tr className="text-center">
                      <td style={{ fontWeight: "bold" }}>Bonus Days</td>
                      <td>0</td>
                      <td>210</td>
                      <td>421</td>
                    </tr> */}
                    {/* <tr className="text-center">
                      <td style={{ fontWeight: "bold" }}>Bonus Liters</td>
                      <td>0</td>
                      <td>210</td>
                      <td>421</td>
                    </tr> */}
                    <tr className="text-center">
                      <td style={{ fontWeight: "bold" }}>Total Liters</td>
                      <td>1263</td>
                      <td>2736</td>
                      <td>5473</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr className="text-center">
                      <td style={{ fontWeight: "bold" }}>Total</td>
                      <td style={{ fontWeight: "bold", color: "black" }}>1347/-</td>
                      <td style={{ fontWeight: "bold", color: "black" }}>2294/-</td>
                      <td style={{ fontWeight: "bold", color: "black" }}>4188/-</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        )}

        {selectedPlan === 'Quad' && (
          <div className="row">
            <div className="col-12 col-md-6 mb-4">
              <img src={banner} alt="Banner" className="img-fluid w-50 mb-3" />
              <div className="table-responsive">
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr className="text-center">
                      <th>Advantages</th>
                      <th>Info</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      <td>Flexible Installation</td>
                      <td>Flexible Installation</td>
                    </tr>
                    <tr className="text-center">
                      <td>Smart LED Indication</td>
                      <td>Smart LED Indication</td>
                    </tr>
                    <tr className="text-center">
                      <td>Long Cartridge Life</td>
                      <td>Long Cartridge Life</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-12 col-md-6 mb-4">
              <div className="table-responsive">
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr className="text-center text-white bg-primary">
                      <th>Benefits</th>
                      <th>3 months</th>
                      <th>6 months</th>
                      <th>12 months</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      <td style={{ fontWeight: "bold" }}>Recharge</td>
                      <td>2047</td>
                      <td>3094</td>
                      <td>6188</td>
                    </tr>
                    {/* <tr className="text-center">
                      <td style={{ fontWeight: "bold" }}>Assembly Material</td>
                      <td>599</td>
                      <td>599</td>
                      <td>599</td>
                    </tr> */}
                    <tr className="text-center">
                      <td style={{ fontWeight: "bold" }}>Discount</td>
                      <td>199</td>
                      <td>299</td>
                      <td>599</td>
                    </tr>
                    {/* <tr className="text-center">
                      <td style={{ fontWeight: "bold" }}>Bonus Days</td>
                      <td>100</td>
                      <td>210</td>
                      <td>421</td>
                    </tr> */}
                    {/* <tr className="text-center">
                      <td style={{ fontWeight: "bold" }}>Bonus Liters</td>
                      <td>100</td>
                      <td>210</td>
                      <td>421</td>
                    </tr> */}
                    <tr className="text-center">
                      <td style={{ fontWeight: "bold" }}>Total Liters</td>
                      <td>2263</td>
                      <td>3736</td>
                      <td>6473</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr className="text-center">
                      <td style={{ fontWeight: "bold" }}>Total</td>
                      <td style={{ fontWeight: "bold", color: "black" }}>2347/-</td>
                      <td style={{ fontWeight: "bold", color: "black" }}>3294/-</td>
                      <td style={{ fontWeight: "bold", color: "black" }}>6188/-</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        )}

        {selectedPlan === 'Hexa' && (
          <div className="row">
            <div className="col-12 col-md-6 mb-4">
              <img src={banner} alt="Banner" className="img-fluid w-50 mb-3" />
              <div className="table-responsive">
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr className="text-center">
                      <th>Advantages</th>
                      <th>Info</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      <td>Flexible Installation</td>
                      <td>Flexible Installation</td>
                    </tr>
                    <tr className="text-center">
                      <td>Smart LED Indication</td>
                      <td>Smart LED Indication</td>
                    </tr>
                    <tr className="text-center">
                      <td>Long Cartridge Life</td>
                      <td>Long Cartridge Life</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-12 col-md-6 mb-4">
              <div className="table-responsive">
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr className="text-center text-white bg-primary">
                      <th>Benefits</th>
                      <th>3 months</th>
                      <th>6 months</th>
                      <th>12 months</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      <td style={{ fontWeight: "bold" }}>Recharge</td>
                      <td>3047</td>
                      <td>4094</td>
                      <td>8188</td>
                    </tr>
                    {/* <tr className="text-center">
                      <td style={{ fontWeight: "bold" }}>Assembly Material</td>
                      <td>799</td>
                      <td>799</td>
                      <td>799</td>
                    </tr> */}
                    <tr className="text-center">
                      <td style={{ fontWeight: "bold" }}>Discount</td>
                      <td>299</td>
                      <td>399</td>
                      <td>799</td>
                    </tr>
                    {/* <tr className="text-center">
                      <td style={{ fontWeight: "bold" }}>Bonus Days</td>
                      <td>200</td>
                      <td>310</td>
                      <td>521</td>
                    </tr> */}
                    {/* <tr className="text-center">
                      <td style={{ fontWeight: "bold" }}>Bonus Liters</td>
                      <td>200</td>
                      <td>310</td>
                      <td>521</td>
                    </tr> */}
                    <tr className="text-center">
                      <td style={{ fontWeight: "bold" }}>Total Liters</td>
                      <td>3263</td>
                      <td>4736</td>
                      <td>7473</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr className="text-center">
                      <td style={{ fontWeight: "bold" }}>Total</td>
                      <td style={{ fontWeight: "bold", color: "black" }}>3347/-</td>
                      <td style={{ fontWeight: "bold", color: "black" }}>4294/-</td>
                      <td style={{ fontWeight: "bold", color: "black" }}>8188/-</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlandetailsCards;
