import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import aboutbanner from "../assets/images/aboutbanner.jpg";
import Sidebar from "./Sidebar";
import Form from "react-bootstrap/Form";
import Franchise from "./Franchise";
import Contactform from "./Contactform";

function Howitworks() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const borderColor = {
    border: "0.1px solid lightgray",
    backgroundColor: "white",
  };

  const textColor = {
    color : "#002c8f"
  }

  const [banners, setBanners] = useState([]);
  const handleUpdate = (data) => {
    setBanners(data);
  };

  return (
    <div>
      <Header onBannersData={handleUpdate}  />
      {/* <Sidebar /> */}

   

      <section className="about-section">
      <section
        className="page-title centred"
        style={{ backgroundImage: `url(${"https://api.kanavneer.com/"+ banners.aboutusImage})` }}
      >
        <div className="shape" />
        <div className="auto-container">
          <div className="content-box">
            <h1 style={{color:"#002c8f"}}>How it Works</h1>
            <ul className="bread-crumb clearfix">
              <li>
                <a href="/">Home</a>
              </li>
              <li>How it Works</li>
            </ul>
          </div>
        </div>
      </section>

        {/* CONTENT */}

        <Franchise/>

        {/* contact information */}
     <Contactform />

        {/* map  */}
        <div className="row">
          <div className="col-lg-12">
            <div id="google-map" className="google-map pb-3">
              <iframe
                src="https://maps.google.com/maps?q=SHREE+LUXMI+GANESH+UDYOG+PRIVATE+LIMITED+++++++++++++++++++HNO+4-7-8%2F17%2F1%2FB+Flat+No.103%2FA%2F1%2C+Shivani+Residency%2C+Bypass+Road%2C+Sangareddy%2C+Telangana%2C+502001&t=&z=13&ie=UTF8&iwloc=&output=embed"
                style={{
                  height: "400px",
                  width: "100%",
                  borderRadius: 20,
                  marginLeft: "10px",
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Howitworks;
