import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import kalvaneerlogo from "../assets/images/kalvaneerlogo.png";
import { FaPhoneAlt } from "react-icons/fa"; // this is the call Icon
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { toBeRequired } from "@testing-library/jest-dom/matchers";

function Header({onBannersData}) {
  const borderColor = {
    border: "0.1px solid lightgray",
    backgroundColor: "white",
  };

  const [show, setshow] = useState(false);

  const shows = show == true ? "mobile-menu-visible" : "";

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setInterval(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearInterval(timer);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    allBanner()
  }, []);


  const allBanner=()=>{
    axios.post("https://api.kanavneer.com/v1/kanavneelapi/web/webbanners").then((res)=>{
      if (onBannersData) {
        onBannersData(res?.data?.webbanner)
      }

      
    })
  }

  const [show2, setShow2] = useState(false);

  const handleClose = () => setShow2(false);
  const handleShow = () => setShow2(true);

  const [form, setForm] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    location: "",
    description: "",
    currentBusiness: "",
  });

  const handleChange = (e) => {
    const myForm = { ...form };
    myForm[e.target.name] = e.target.value;
    setForm(myForm);
  };

  const addForm = (e) => {
    e.preventDefault();
    const bodydata = {
      name: form.name,
      email: form.email,
      phoneNumber: form.phoneNumber,
      location: form.location,
      description: form.description,
      currentBusiness: form.currentBusiness,
    };
    axios
      .post(
        "https://api.kanavneer.com/v1/kanavneelapi/web/enquiryform/addenquiry",
        bodydata
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast.success(res.data.message);
            setForm({
              name: "",
              email: "",
              phoneNumber: "",
              location: "",
              description: "",
              currentBusiness: "",
            });
            handleClose()
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message);
          }
        }
      );
  };

  return (
    <>
      <div className="tm-header-topside">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8">
              <ul className="tm-header-topside-infoleft">
                <li style={{ paddingLeft: 40 }}>
                  <b>
                    <i
                      class="fal fa-envelope-open-text"
                      style={{ marginBottom: "12px" }}
                    ></i>
                  </b>
                  <a href="mailto:info@kanavneer.com">info@kanavneer.com</a>
                </li>
                <li
                  style={{ position: "relative", bottom: "6px", left: "10px" }}
                >
                  <b>
                    <FaPhoneAlt
                      style={{
                        position: "relative",
                        top: "20px",
                        right: "14px",
                      }}
                    />{" "}
                    {/* Contact Icon */}
                  </b>
                  <a href="tel:+91 779 901 87 77">+91 7799018777</a>
                </li>
                <li>
                  <b>
                    <i
                      class="fal fa-clock"
                      style={{ marginBottom: "12px" }}
                    ></i>
                  </b>
                  <span>Mon - Sat</span> : 10:00 AM - 08:00 PM
                </li>
              </ul>
            </div>
            <div className="col-lg-4">
              <ul className="tm-header-topside-inforight">
                <li />
                <li>
                  <a href="https://www.facebook.com/" target="blank">
                    <i class="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.twitter.com/" target="blank">
                    <i class="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin-in.com/" target="blank">
                    <i class="fab fa-linkedin-in"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin-in.com/" target="blank">
                    <i class="fab fa-instagram" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class={shows}>
        <header className="main-header">
          <div className="header-lower">
            <div className="outer-box">
              <div className="logo-box">
                <figure className="logo">
                  <a href="/">
                    <img
                      src={kalvaneerlogo}
                      alt=""
                      style={{ height: "40px" }}
                    />
                  </a>
                </figure>
              </div>
              <div className="menu-area clearfix">
                <div
                  className="mobile-nav-toggler"
                  onClick={() => {
                    setshow(!show);
                  }}
                >
                  <i className="icon-bar" />
                  <i className="icon-bar" />
                  <i className="icon-bar" />
                </div>
                <nav
                  className="main-menu navbar-expand-md navbar-light"
                  id="head"
                >
                  <div
                    className="collapse navbar-collapse show clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation clearfix">
                      <li>
                        <NavLink to="/">Home</NavLink>
                      </li>
                      <li>
                        <NavLink to="/about">About Us</NavLink>
                      </li>
                      <li>
                        <NavLink to="/Plan">Plan</NavLink>
                      </li>
                      <li>
                        <NavLink to="/howitworks">How it Works</NavLink>
                      </li>
                      <li>
                        <NavLink to="/contactus">Contact Us</NavLink>
                      </li>
                      <li>
                        <div onClick={handleShow} className="btn-box">
                          <a
                            type="button"
                            style={{ marginBottom: "0px" }}
                            className="theme-btn btn-two"
                          >
                             Book Now
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>

          <div className="sticky-header">
            <div className="outer-box">
              <div className="logo-box">
                <figure className="logo">
                  <a href="/">
                    <img
                      src={kalvaneerlogo}
                      alt=""
                      style={{ height: "40px" }}
                    />
                  </a>
                </figure>
              </div>
              <div className="menu-area clearfix">
                <nav
                  className="main-menu navbar-expand-md navbar-light"
                  id="head"
                >
                  <div
                    className="collapse navbar-collapse show clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation clearfix">
                      <li>
                        <NavLink to="/">Home123</NavLink>
                      </li>
                      <li>
                        <NavLink to="/about">About Us</NavLink>
                      </li>
                      <li>
                        <NavLink to="/Plan">Plan</NavLink>
                      </li>
                      <li>
                        <NavLink to="/howitworks">How it Works</NavLink>
                      </li>
                      <li>
                        <NavLink to="/contactus">Contact Us</NavLink>
                      </li>
                      <li>
                        <div onClick={handleShow} className="btn-box">
                          <a
                            type="button"
                            style={{ marginBottom: "0px", marginTop:"15px" }}
                            className="theme-btn btn-two"
                          >
                            Book Now
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </header>

        <div className="mobile-menu">
          <div className="menu-backdrop" />
          <div
            className="close-btn"
            onClick={() => {
              setshow(!show);
            }}
          >
            <i className="fas fa-times" />
          </div>
          <nav className="menu-box">
            <div className="nav-logo">
              <a href="index">
                <img src={kalvaneerlogo} alt="" style={{ height: "40px" }} />
              </a>
            </div>

            <div
              className="collapse navbar-collapse show clearfix"
              id="navbarSupportedContent"
            >
              <ul className="navigation clearfix">
                <li>
                  <NavLink to="/">Home</NavLink>
                </li>
                <li>
                  <NavLink to="/about">About</NavLink>
                </li>
                <li>
                  <NavLink to="/Plan">Plan</NavLink>
                </li>
                <li>
                  <NavLink to="/howitworks">How it Works</NavLink>
                </li>
                <li>
                  <NavLink to="/contactus"> Contact Us</NavLink>
                </li>
                <li>
                        <div onClick={handleShow} className="btn-box">
                          <a
                            type="button"
                            style={{ marginBottom: "0px", background:"white" }}
                            className="theme-btn btn-two"
                          >
                             Book Now
                          </a>
                        </div>
                      </li>
              </ul>
            </div>

            <div className="contact-info">
              <h4>Contact Info</h4>
              <ul>
                <li>
                  SHREE LUXMI GANESH UDYOG PRIVATE LIMITED HNO 4-7-8/17/1/B Flat
                  No.103/A/1, Shivani Residency, Bypass Road, Sangareddy,
                  Telangana, 502001{" "}
                </li>
                <li>
                  <a href="phn:+91 779 901 87 77">+91 7799018777</a>
                </li>
                <li>
                  <a href="info@kanavneer.com">info@kanavneer.com</a>
                </li>
              </ul>
            </div>
            <div className="social-links">
              <ul className="clearfix">
                <li>
                  <a href="https://twitter.com/">
                    <span className="fab fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/">
                    <span className="fab fa-facebook-square" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/">
                    <span className="fab fa-instagram" />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin-in.com/" target="blank">
                    <i className="fab fa-linkedin-in" />
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>{" "}
      <Modal   show={show2} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> Book Now</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{margin:"0px", background:"#ebf3fa"}}>
        <div className="faq-sidebar">
        <div className="form-inner">
          <form
            onSubmit={(e) => {
              addForm(e);
            }}
          >
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="text"
                name="name"
                value={form.name}
                required
                onChange={(e) => {
                  handleChange(e);
                }}
                placeholder="Your Name"
                style={borderColor}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="email"
                name="email"
                required
                value={form.email}
                onChange={(e) => {
                  handleChange(e);
                }}
                placeholder="Your Email"
                style={borderColor}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="tel"
                required
                name="phoneNumber"
                value={form.phoneNumber}
                onChange={(e) => {
                  handleChange(e);
                }}
                placeholder="Phone Number"
                style={borderColor}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="text"
                required
                name="location"
                placeholder="location"
                value={form.location}
                onChange={(e) => {
                  handleChange(e);
                }}
                style={borderColor}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="text"
                required
                name="currentBusiness"
                placeholder="Current Business"
                value={form.currentBusiness}
                onChange={(e) => {
                  handleChange(e);
                }}
                style={borderColor}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Control
                placeholder="Message"
                required
                as="textarea"
                rows={3}
                name="description"
                value={form.description}
                onChange={(e) => {
                  handleChange(e);
                }}
                style={borderColor}
              />
            </Form.Group>
            <div className="form-group message-btn">
              <button type="submit" className="theme-btn btn-one">
                Submit Now
              </button>
            </div>
          </form>
          </div>
          </div>
        </Modal.Body>
       
      </Modal>
      <ToastContainer />
    </>
  );
}

export default Header;
